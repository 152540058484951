import Row from './Row'
import style from '../../styles/components/home-page/home-page'

const Sections = ({ sections }) => (
  <>
    <div className='sections-container'>
      {sections?.map((section, idx) => (
        <div key={section?.name} className='section'>
          <div className='section-title'>{section?.name}</div>
          <Row section={section.stories} layout={idx % 2 === 0 ? '' : 'large'} />
        </div>
      ))}
    </div>
    <style jsx>{style}</style>
  </>
)

export default Sections
