import { FALLBACK_IMAGE_URL } from '../../utils/constants'
import resolveImageDomain from './resolveImageDomain'

const resolveImage = ({ file, size: { width, height }, quality = 100 }) => ({
  url: `https:${resolveImageDomain(file?.url) || FALLBACK_IMAGE_URL}?fit=thumb&w=${width}&h=${height}&q=${quality}`,
  width: width || file?.details?.image?.width || 1200,
  height: height || file?.details?.image?.height || 675
})

export default resolveImage
