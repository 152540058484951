import THUMBNAIL_SIZE from '../../utils/thumbnailSize'
import resolveImage from './resolveImage'

const getDuration = (story) => story?.fields?.videoFile?.fields?.duration || ''

const extractStoryCollection = (stories) =>
  stories?.map((story) => ({
    title: story?.fields?.title || '',
    slug: story?.fields?.slug || '',
    updatedAt: story?.fields?.publishedAt || '',
    thumbnail: resolveImage({ file: story?.fields?.thumbnail?.fields?.file, size: THUMBNAIL_SIZE.FILM, quality: 50 }),
    duration: getDuration(story),
    description: story?.fields?.description || ''
  }))

export default extractStoryCollection
