import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from 'next/image'
import style from '../../styles/components/home-page/carousel'

const Carousel = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: () => (
      <div>
        <div className='carousel-dot' />
        <style jsx>{style}</style>
      </div>
    )
  }

  return (
    <>
      <div className='carousel-container'>
        <Slider {...settings}>
          {slides?.map((slide) => (
            <a href={slide.url} key={slide.url} id={slide.url} className='promo-container'>
              <Image layout='responsive' width={slide.banner.width} height={slide.banner.height} src={slide.banner.url} alt={slide.name} />
            </a>
          ))}
        </Slider>
      </div>
      <style jsx>{style}</style>
    </>
  )
}

export default Carousel
