import getConfig from 'next/config'
import Document, { Html, Head, Main, NextScript } from 'next/document'

class OurDocument extends Document {
  render() {
    const { vapidPublicKey, websitePushId, appKey, token } = getConfig().publicRuntimeConfig.airship
    const { inAmpMode } = this.props

    return (
      <Html>
        <Head>
          {!inAmpMode && (
            <script
              type='text/javascript'
              dangerouslySetInnerHTML={{
                __html: `!function(n,r,e,t,c){var i,o="Promise"in n,u={then:function(){return u},catch:function(n){
          return n(new Error("Airship SDK Error: Unsupported browser")),u}},s=o?new Promise((function(n,r){i=function(e,t){e?r(e):n(t)}})):u
          ;s._async_setup=function(n){if(o)try{i(null,n(c))}catch(n){i(n)}},n[t]=s;var a=r.createElement("script");a.src=e,a.async=!0,a.id="_uasdk",
          a.rel=t,r.head.appendChild(a)}(window,document,'https://aswpsdkus.com/notify/v1/ua-sdk.min.js',
            'UA', {
              vapidPublicKey: '${vapidPublicKey}',
              websitePushId: '${websitePushId}',
              appKey: '${appKey}',
              token: '${token}'
          });`
              }}
            ></script>
          )}
          <script async src='https://cdn.ampproject.org/v0.js'></script>
          <script async custom-element='amp-analytics' src='https://cdn.ampproject.org/v0/amp-analytics-0.1.js'></script>
          <script async custom-element='amp-ad' src='https://cdn.ampproject.org/v0/amp-ad-0.1.js'></script>
        </Head>
        <body>
          <Main />
          <NextScript />
          {!inAmpMode && (
            <>
              <div id='taboola-newsroom' />
              <script
                dangerouslySetInnerHTML={{
                  __html: `
           window._taboola = window._taboola || [];
           _taboola.push({
              mode: 'rbox-tracking',
              container: 'taboola-newsroom',
              placement: 'Newsroom'
           });
      `
                }}
              />
            </>
          )}
        </body>
      </Html>
    )
  }
}

export default OurDocument
