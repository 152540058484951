import Metadata from '../Metadata'
import BreakingNewsBanner from '../BreakingNewsBanner'
import Carousel from './Carousel'
import Sections from './Sections'
import style from '../../styles/components/home-page/home-page'

const HomePage = ({ metadata, promos, sections, banner }) => {
  return (
    <>
      <Metadata metadata={metadata} />
      <div className='home-container'>
        {banner?.title && <BreakingNewsBanner banner={banner} />}
        <div className='hero-background'>
          <Carousel slides={promos} />
        </div>
        <Sections sections={sections} />
      </div>
      <style jsx>{style}</style>
    </>
  )
}

export default HomePage
