import resolveResponse from 'contentful-resolve-response'
import fetchQueryWrapper from '../network/fetchQueryWrapper'
import { getCollectionParams } from '../network/queryParams'
import { capitalize } from '../utils/capitalizeFirstLetter'
import { CONTENT_TYPE } from '../utils/contentTypes'
import logger from '../utils/winston-logger/winston-logger'
import extractBanner from '../data/transformers/extractBreakingNewsBanner'
import extractHomePage from '../data/transformers/extractHomePage'
import extractStoryCollection from '../data/transformers/extractStoryCollection'
import HomePage from '../components/HomePage/HomePage'

const Home = ({ data, sections, banner }) => (
  <HomePage metadata={data?.metadata} promos={data?.promos} sections={sections} banner={banner} />
)

const resolveSectionStories = async (entry) => {
  if (entry?.sys?.contentType?.sys?.id === 'category') {
    const data = await fetchQueryWrapper(getCollectionParams({ categoryId: entry?.sys?.id, limit: 4 }), resolveResponse)

    return extractStoryCollection(data) || []
  } else {
    return extractStoryCollection(entry?.fields?.stories) || []
  }
}

export const getSections = async (data) =>
  await Promise.all(
    data?.[0]?.fields?.rows?.map(async (row) => ({
      name: capitalize(row?.fields?.name),
      stories: await resolveSectionStories(row)
    }))
  )

export async function getServerSideProps() {
  try {
    const homePageData = await fetchQueryWrapper({ content_type: CONTENT_TYPE.HOMEPAGE, include: 3 }, resolveResponse)

    const breakingNewsBannerData = await fetchQueryWrapper(
      {
        content_type: CONTENT_TYPE.BANNER,
        order: '-sys.updatedAt'
      },
      resolveResponse
    )

    return {
      props: {
        data: extractHomePage(homePageData),
        banner: extractBanner(breakingNewsBannerData),
        sections: await getSections(homePageData)
      }
    }
  } catch (error) {
    logger?.error('Error in index.page.js ', error)
    return { notFound: true }
  }
}

export default Home
