import { STORIES_PER_PAGE } from '../utils/constants'

export const getCollectionParams = ({ categoryId = null, showId = null, authorId = null, skip = 0, limit = STORIES_PER_PAGE }) => ({
  content_type: 'story',
  limit,
  skip,
  ...(categoryId && { 'fields.categories.sys.id': categoryId }),
  ...(showId && { 'fields.show.sys.id': showId }),
  ...(authorId && { 'fields.authors.sys.id': authorId }),
  'fields.indexed[nin]': false,
  order: '-fields.publishedAt'
})
